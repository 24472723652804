import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { Exports } from '@wix/editor-platform-sdk-types';
import { withMembersArea } from '@wix/members-area-integration-kit';
import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import { Experiment } from './constants';
import {
  configureMyBusinessActions,
  configurePages,
  configurePagesTab,
  EditorScriptContext,
  handleEvents,
  installApp,
  REQUIRED_MEMBERS_AREA_APPS,
  setStatesForPages,
} from './editor';

const _editorReady: EditorReadyFn = async (editorSDK, appDefinitionId, { firstInstall, origin }, flowAPI) => {
  const context: EditorScriptContext = {
    editorSDK,
    flowAPI,
    origin,
  };

  if (firstInstall) {
    await installApp(context);
  }

  await setStatesForPages(context);
  await handleEvents(context);
};

const _getAppManifest: GetAppManifestFn = async (options, editorSDK, { initialAppData, origin }, flowAPI) => {
  const { appManifestBuilder } = options;
  const {
    translations: { t },
    experiments,
  } = flowAPI;

  const isMyBusinessEnabled = experiments.enabled(Experiment.MyBusiness);

  const context: EditorScriptContext = {
    editorSDK,
    flowAPI,
    origin,
  };

  const baseManifest = await fetchWidgetsStageData({
    initialAppData,
    editorSDK,
    translateFunc: t,
  });

  appManifestBuilder
    .withJsonManifest(baseManifest)
    .configurePagesTab((pagesTabBuilder) => configurePagesTab(context, pagesTabBuilder))
    .configurePages((pagesBuilder) => configurePages(context, pagesBuilder));

  if (isMyBusinessEnabled) {
    appManifestBuilder.configureManagementActions((managementActionsBuilder) => {
      configureMyBusinessActions(context, managementActionsBuilder);
    });
  }

  return appManifestBuilder.build();
};

export const _exports: Exports = (editorSDK) => ({
  editor: {
    async getSelectedWidgetDesignPresetId({ widgetRef }) {
      const selectedPreset = await editorSDK.application.appStudioWidgets.getPreset('', {
        componentRef: widgetRef,
      });

      return `externalPreset-${selectedPreset.style}`;
    },
  },
});

export const { editorReady, getAppManifest, handleAction, onEvent, exports } = withMembersArea(
  {
    editorReady: _editorReady,
    getAppManifest: _getAppManifest,
    handleAction: async () => {},
    exports: _exports,
  },
  {
    membersAreaApps: REQUIRED_MEMBERS_AREA_APPS,
  },
);
